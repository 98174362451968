.wrapper {
  @apply text-center max-w-4xl;

  & :global(div[data-block="core/paragraph"]:last-of-type) {
    @apply pb-0;
    & a {
      @apply font-bold no-underline text-green-100;
    }
  }

  & :global(div.report-chapter-number) {
    @apply pb-0;
  }

  & :global(p.is-style-report-chapter-number) {
    @apply text-green-100 font-bold font-serif text-2xl;
  }

  & :global(.is-style-report-chapter-title) {
    @apply text-green-100;

    @apply text-5xl;

    @apply md:text-7xl md:leading-[5rem];
    @apply lg:text-[7rem] lg:leading-[7rem];
  }
}
